<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="d-flex justify-center align-stretch">
	<div class="k-main-collection">
		<div class="d-flex">
			<h2 class="k-main-collection-header k-page-title d-flex mb-4"><v-icon large color="primary" class="mr-4" style="margin-top:4px">fas fa-cubes-stacked</v-icon><b>My Content Collections</b></h2>
			<v-spacer/>
			<v-btn v-if="signed_in && !reorder_on" color="secondary" class="k-tight-btn k-nocaps-btn" @click="go_to_favorites"><v-icon small class="mr-2" >fas fa-cubes-stacked</v-icon><span>Favorite Collections</span></v-btn>
			<v-btn v-if="signed_in" color="secondary" class="ml-2 k-tight-btn k-nocaps-btn" @click="reorder_on=!reorder_on"><v-icon small class="mr-2">fas fa-up-down-left-right</v-icon>{{reorder_on ? 'Stop Reordering' : 'Reorder'}}</v-btn>
		</div>
		<div v-show="initialized&&custom_tab_content_description" class="k-custom-tab-content-description" :style="site_config.customized_tabs.mycollections.description_style" v-html="custom_tab_content_description"></div>

		<div v-show="initialized">
			<div v-if="!new_lp">
				<!-- we're not currently allowing for studentish roles in cureum, but this is here for when we do... -->
				<div v-if="studentish_role&&my_collection_lps.length==0&&signed_in">You have not subscribed to any content collections.</div>
				<div v-if="!studentish_role&&my_collection_lps.length==0&&signed_in">You have not subscribed to or created any content collections.</div>
				<div v-if="my_collection_lps.length==0&&!signed_in">Sign in to {{site_config.app_name}} to start collecting and creating lesson plans, student activities, and other resources.</div>

				<!-- <div v-if="my_collection_lps.length>0" style="width:500px; margin:auto;"><v-text-field v-model="search_string" prepend-inner-icon="fa fa-search" rounded clearable clear-icon="fa fa-times-circle" label="Search collection titles…" single-line hide-details outlined dense background-color="#fff"></v-text-field></div> -->
				<div class="d-flex" v-if="signed_in">
					<v-spacer/>
					<div v-if="my_collection_lps.length>0" style="width:260px"><v-text-field v-model="search_string" prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle" label="Search collection titles" single-line hide-details outlined dense background-color="#fff"></v-text-field></div>
					<v-btn outlined class="k-search-initialize-btn ml-2" style="width:260px" @click="initiate_search">Search my content<div class="k-search-initialize-btn-icon-div"><v-icon small>fas fa-search</v-icon></div></v-btn>
					<v-spacer/>
				</div>

				<div class="k-main-collection-body">
					<!-- This is for when there is no  -->
					<draggable v-bind="drag_options" v-model="category_order" draggable=".k-my-collection-category-draggable" @change="category_order_changed" class="d-flex flex-wrap justify-center">
						<div v-for="(name, i) in category_order" :key="i" class="k-my-collection-category" :class="name.split('_')[1] == 'My Content Sandbox' || !name.split('_')[1] || !reorder_on ? '' : 'k-my-collection-category-draggable'" :style="{ padding: name === '998_' ? '12px 12px 12px 12px' : '36px 12px 12px 12px' }">
							<div class="k-my-collection-category-label" v-html="name in collection_categories ? collection_categories[name].display_cat_name : ''"></div>
							<draggable v-if="name in collection_categories" v-bind="drag_options" draggable=".k-repo-draggable" class="d-flex flex-wrap justify-center" group="categories" :data-list-name="name" :list="collection_categories[name].lps" @change="repo_order_changed(name, name in collection_categories ? collection_categories[name].lps : []), $event">
								<div v-for="lp in collection_categories[name].lps" class="k-elevated k-main-collection-item k-lp-tile" :class="[lp_css(lp), lp.lp_id == 1 || !reorder_on ? '' : 'k-repo-draggable']" :style="[lp_style(lp), {'color': U.get_contrast_color(lp.color)}]" @click="go_to_lp(lp)">
									<span v-html="lp_title(lp)"></span>
									<div v-if="reorder_on && lp.lp_id != 1" style="position:absolute; right:12px; bottom:12px; font-size:14px;">
										<v-icon>fas fa-up-down-left-right</v-icon>
									</div>
								</div>
							</draggable>
						</div>
					</draggable>
				</div>

			</div>

			<div v-if="!new_lp&&!small_screen&&signed_in" class="mt-4">
				<v-btn large class="k-tight-btn k-nocaps-btn mx-1" @click="enter_subscription_code"><v-icon class="mr-3 ml-6">fas fa-cubes-stacked</v-icon><span class="mr-6">SUBSCRIBE to a Content Collection</span></v-btn>
				<v-btn v-if="!studentish_role" large class="k-tight-btn k-nocaps-btn mx-1" @click="create_new_collection"><v-icon class="mr-3 ml-6">fas fa-cubes-stacked</v-icon><span class="mr-6">CREATE a New Content Collection</span></v-btn>
			</div>

			<CollectionEdit v-if="new_lp" :learning_progression="new_lp" @editor_cancel="new_lp=null" />
		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CollectionEdit from '../collections/CollectionEdit'
import MiniNav from '../main/MiniNav'
import draggable from 'vuedraggable';

export default {
	components: { draggable, CollectionEdit, MiniNav },
	data() { return {
		initialized: false,
		search_string:'',
		search_terms: [],
		new_lp: null,
		category_order: [],
		drag_options: {
			animation: 200,
		},
		reorder_on: false,
	}},
	computed: {
		...mapState(['site_config', 'user_info', 'all_courses_loaded', 'all_courses', 'course_update_trigger']),
		...mapGetters(['small_screen', 'signed_in', 'my_default_collection', 'studentish_role']),
		custom_tab_content_description() { return this.site_config.customized_tabs?.mycollections?.description },
		last_collections_viewed: {
			get() { return this.$store.state.lst.last_collections_viewed },
			set(val) { this.$store.commit('lst_set', ['last_collections_viewed', val]) }
		},
		my_collection_lps() {
			let arr = this.all_courses.filter(lp => {
				// when we first create a new LP to be entered, its title will be false
				if (lp.title == '') return false

				// for inactive lp's, only show to people explicitly authorized as viewers (this includes admins)
				if (lp.active == 'no') {
					if (!lp.user_can_view_lp()) return false
				}

				// we always include the default collection here
				if (lp.lp_id == 1) return true

				// then we also include any collections that are in the 'my' category
				if (lp.collection_type == 'my') return true
				// if (!lp.agency_sanctioned) return true

				return false
			})
			return arr
		},
		my_collection_lps_to_show() {
			let arr = this.my_collection_lps.filter(lp => {
				// if all search_terms appear in the title, return true
				let tlc = lp.title.toLowerCase()
				for (let term of this.search_terms) {
					if (tlc.indexOf(term) == -1) return false
				}

				return true
			})

			arr.sort((a,b)=>{
				if (a.sequence != b.sequence) return a.sequence - b.sequence

				// default sort by title
				if (a.title < b.title) return -1
				if (b.title < a.title) return 1

				return 0
			})

			return arr
		},
		my_collection_categories() {
			const arr = []
			const s = new Set()
			for (const lp of this.my_collection_lps) {
				// small optimization (shouldn't matter much since not many categories)
				if (!s.has(lp.repo_category)) {
					s.add(lp.repo_category)
					arr.push(lp.repo_category)
				}
			}
			// just to make sure, shouldn't ever happen since all_courses[0] is the my content sandbox
			if (arr[0] !== '999_My Content Sandbox' && arr[arr.length-1] !== '998_') return arr.sort(U.natural_sort)
			return arr
		},
		collection_categories() {
			// MC: the categories are stored with the key={full_category_name}, full_category_name = 5_cat name,
			// 	   where the number represents the order of it and the values is lps, and the display name
			//     we do this so we can populate the category_order by simply sorting the keys
			let cats = {}
			for (let collection of this.my_collection_lps_to_show) {
				const display_cat_name = collection.repo_category.split('_').slice(1).join('_');
				if (cats && !(collection.repo_category in cats)) {
					cats[collection.repo_category] = {}
					cats[collection.repo_category].lps = []
					cats[collection.repo_category].display_cat_name = display_cat_name
				}
				if (!display_cat_name) cats[collection.repo_category].display_cat_name = ''
				cats[collection.repo_category].lps.push(collection)
			}

			// sort each category
			for (let collection_arr of Object.values(cats)) {
				collection_arr.lps.sort((a,b)=>U.natural_sort(a.title, b.title))
				collection_arr.lps.sort((a,b)=>{
					if (a.sequence != b.sequence) return a.sequence - b.sequence
					return 0
				})
			}
			return cats
		},
	},
	watch: {
		search_string() {
			if (empty(this.search_string)) {
				this.search_terms = []
			} else {
				// convert string to lower case and split on spaces
				this.search_terms = $.trim(this.search_string).toLowerCase().split(/\s+/)
			}
		}
	},
	created() {
		this.category_order = Object.keys(this.collection_categories).sort((a,b)=>U.natural_sort(a, b))
	},
	mounted() {
		if (!this.all_courses_loaded) {
			this.$store.dispatch('get_all_courses', 'initial').then(()=>{
				this.$nextTick(()=>{
					this.initialize()
				})
			}).catch(()=>{
				console.log('error in get_all_courses')
				this.back_to_classes()
			})
		} else {
			this.initialize()
		}
	},
	methods: {
		initialize() {
			// console.log('MyCollectionsIndex initialized', this.$router.history.current)
			// if the user only has a default collection, go to the default collection here -- if the user isn't studentish
			// NOT FOR NOW; we could bring this back later though
			// if (!this.studentish_role) {
			// 	if (['mycollections', 'welcome'].includes(this.$router.history.current.name) && this.my_collection_lps.length == 1) {
			// 		this.$router.push({ path: this.my_default_collection.vue_route() })
			// 	}
			// }

			this.initialized = true

			// if state.create_my_collection is true, it means that the user opened their window right to the default collection, then clicked to create a new collection (see Collection.vue)
			if (this.$store.state.create_my_collection == true) {
				this.$store.commit('set', ['create_my_collection', false])
				// so call create_new_collection here
				this.create_new_collection()
			}
		},

		go_to_favorites() {
			window.history.replaceState(null, '', '/mycollections')
			if (!this.signed_in) this.$store.commit('lst_set', ['unsigned_index_view_flavor', 'favorites'])
			else this.$store.commit('lst_set', ['my_index_view_flavor', 'favorites'])
		},

		lp_css(lp) {
			// let s = vapp.color_from_number(lp.course_code)
			// let s = U.subject_tile_css(lp)
			let s = ''
			if (lp.title.length > 50) {
				s += ' k-lp-tile-extra-long-title'
			} else if (lp.title.length > 30) {
				s += ' k-lp-tile-long-title'
			}
			if (lp.active == 'no') {
				s += ' k-lp-tile-inactive'
			}
			return s
		},

		lp_title(lp) {
			let s = ''
			s += '<div>'
			const contrast_color = U.get_contrast_color(lp.color)

			// start with icon indicating collection type
			if (lp.collection_type == 'course') s += `<i style="color: ${contrast_color}" class="fas fa-chalkboard"></i>`
			else if (lp.collection_type == 'repo') s += `<i style="color: ${contrast_color}" class="fas fa-diagram-project"></i>`
			else if (lp.collection_type == 'pd') s += `<i style="color: ${contrast_color}" class="fas fa-user-graduate"></i>`
			else s += `<i style="color: ${contrast_color}" class="fas fa-cubes-stacked"></i>`
			// add <wbr> tags after slashes
			s += lp.title.replace(/\//g, '/<wbr>')
				if (lp.active == 'no') {
					s += '<br><div class="red mt-1 mx-auto" style="padding:2px; display:inline-block; font-weight:normal">Inactive</div>'

					// s += ' <b class="red--text">[Inactive]</b>'
				}
			s += '</div>'

			return s
		},
		lp_style(lp) {
			return U.collection_color_style(lp)
		},

		go_to_lp(lp) {
			this.$store.commit('set', ['last_lp_list', 'index'])
			this.$router.push({ path: lp.vue_route() })
		},

		initiate_search() {
			// once we know standards-aligned assets are loaded, show search interface
			vapp.initiate_search({
				caller_component: this,
				dialog_title: 'Search My Content',
				// home_collection: this.collection,
				default_source: 'my',
			})
		},

		create_new_collection() {
			// lps created from here are definitionally NOT agency_sanctioned, and use the "tree" layout
			this.new_lp = new Learning_Progression({
				subject:'', 
				agency_sanctioned: false, 
				lp_layout: 'tree',
				collection_type: 'my',
				active: 'yes',
				use_unit_numbers: false,
				units: [new LP_Unit()],
			})
		},
		enter_subscription_code() {
			vapp.enter_subscription_code()
		},
		update_lp_categories(payload) {
			// note that we set category and/or sequence in the LP's $store representation before calling this; if the service fails (which it shouldn't), we'll alert an error
			// console.warn(payload); return
			if (payload.lps_to_update.length == 0) return
			U.ajax('update_lp_categories', {...payload, from_my_collection: true}, result=>{
				if (result.status != 'ok') {
					this.$alert('Error updating collection order: ' + result.status)
					vapp.ping()		// call ping to check if the session is expired
				}
				this.category_order = Object.keys(this.collection_categories).sort((a,b)=>U.natural_sort(a, b))
			})
		},
		category_order_changed() {
			let payload = {user_id: this.user_info.user_id, lps_to_update: []}

			// for each category...
			for (let i = 0; i < this.category_order.length; ++i) {
				// get old name and construct new name, which incorporates the sequence number at the start
				let old_repo_category = this.category_order[i]
				let new_repo_category = (100+i) + '_' + old_repo_category.replace(/^\d+_/, '')	// first category will be 100, second will be 101, etc.
				if (old_repo_category == '999_My Content Sandbox' || !old_repo_category.split('_')[1]) continue
		
				// if category name changes, we have to update all lps with the old category to have the new category
				if (old_repo_category != new_repo_category) {
					for (let lp of this.my_collection_lps) {
						if (lp.repo_category == old_repo_category) {
							this.$store.commit('set', [lp, 'repo_category', new_repo_category])
							// no need to change the sequence of item in the category
							payload.lps_to_update.push({course_code: lp.course_code, repo_category: new_repo_category})
						}
					}
				}
			}
			this.update_lp_categories(payload)
		},
		repo_order_changed(category_name, new_order, $event) {
			const new_order_course_codes = new_order.map(({course_code}) => course_code)

			let payload = {user_id: this.user_info.user_id, lps_to_update: []}
			for (let i = 0; i < new_order_course_codes.length; ++i) {
				let course_code = new_order_course_codes[i]
				let lp = this.all_courses.find(x=>x.course_code == course_code)
				let sequence = i
				if (!lp) { console.error('couldn’t find lp'); return; }	// shouldn't happen
				this.$store.commit('set', [lp, 'repo_category', category_name])
				if (course_code == 'default') sequence = 998
				this.$store.commit('set', [lp, 'sequence', sequence+1])	// lets make these 1-indexed
				payload.lps_to_update.push({course_code: course_code, repo_category: category_name, sequence: sequence})
			}
			this.update_lp_categories(payload)
		},
	},
}
</script>

<style lang="scss">
.k-my-collection-category {
	position:relative;
	border-radius: 40px;
	background-color:rgba(255, 255, 255, 0.5);
	margin:0 10px 20px 10px;
	min-width:240px;
	display:inline-flex;	// make it shrink to fit whatever is inside of it
	flex-wrap:wrap;
	flex-direction: row;
	align-items:center;
	justify-content: center;

	.k-main-collection-item {
		margin:8px;
	}
}
.k-my-collection-category-label {
	position:absolute;
	width:100%;
	text-align:center;
	top:12px;
	left:0;
	font-weight:bold;
	font-size:16px;
	line-height:20px;
	white-space:nowrap;
	// padding:0 4px;	// this is redundant if white-space is nowrap
}

</style>
